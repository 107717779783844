<template>
    <div class="modal-select-purchase-method">
        <p class="m-b-20 f-22 f-regular c-black">어떻게 결제하시겠어요?</p>
        <div class="btns">
            <div class="btn m-b-10" @click="purchaseInapp">
                <img class="m-r-8" src="@/assets/images/store/inapp.png"/>
                인앱결제 (구글플레이 결제)
            </div>
            <div class="btn" @click="purchaseVirtualAccount">
                <img class="m-r-8" src="@/assets/images/store/virtual_account.png"/>
                무통장 입금 (가상계좌)
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalSelectPurchaseMethod',
    props: ['options'],
    data: () => ({
        step: 0,
    }),
    computed: {
    },
    methods: {
        purchaseInapp() {
            this.$emit('close', 'purchaseInapp')
        },
        purchaseVirtualAccount() {
            this.$emit('close', 'purchaseVirtualAccount')
        },
    },
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#372E66',
                bottom: '#FFFFFF',
            },
        })
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#8A74FF',
                bottom: '#FFFFFF',
            },
        })
    },
}
</script>

<style lang="scss" scoped>
.modal-select-purchase-method {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    border-radius: 20px 20px 0 0;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .btns {
        width: 100%;

        .btn {
            position: relative;
            width: 100%;
            height: 48px;
            font-size: 15px;
            color: black;
            background-color: $grey-02;
            border-radius: 8px;

            img {
                width: 24px;
                height: 24px;
            }
        }
    }
}
</style>
